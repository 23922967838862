import { api } from 'api';
import { DOCUMENTS_MANAGEMENT_API_APPENDIX } from 'constants/baseUrl';

type MetadataSearchItem = {
  id: string,
  fieldName: string,
};

export const getMetadataByIds = (searchMap: MetadataSearchItem[], rootProcessInstanceId: string, taskId: string) => {
  return api.post$(`${DOCUMENTS_MANAGEMENT_API_APPENDIX}/${rootProcessInstanceId}/${taskId}/search`, {
    documents: searchMap,
  });
};
