import { createSelector } from 'reselect';

import { createAsyncActionErrorSelector, createAsyncActionIsLoadingSelector } from 'store/asyncAction';
import { RootState } from 'store/rootReducer';
import { ChannelType } from 'types/settings';
import { ERROR_TYPE } from '#shared/types/common';

import {
  getSettingsRequest, activateChannelRequest, validateChannelRequest, deactivateChannelRequest, verifyChannelRequest,
} from './slice';

export const selectSettingsRootState = (state: RootState) => state.settings;

export const selectSettingsState = (state: RootState) => state.settings.settings;

export const selectChannelEmail = createSelector(
  selectSettingsState,
  (settings) => settings?.channels.find((i) => i.channel === ChannelType.EMAIL),
);

export const selectChannelTimeout = createSelector(
  selectSettingsRootState,
  (settings) => settings.timeout,
);

export const selectChannelDiia = createSelector(
  selectSettingsState,
  (settings) => settings?.channels.find((i) => i.channel === ChannelType.DIIA),
);

export const selectSettingsIsLoading = createSelector(
  createAsyncActionIsLoadingSelector(getSettingsRequest.type),
  createAsyncActionIsLoadingSelector(activateChannelRequest.type),
  createAsyncActionIsLoadingSelector(deactivateChannelRequest.type),
  createAsyncActionIsLoadingSelector(validateChannelRequest.type),
  createAsyncActionIsLoadingSelector(verifyChannelRequest.type),
  (
    getSettingsLoading,
    activateChannelLoading,
    deactivateChannelLoading,
    validateChannelLoading,
    verifyChannelLoading,
  ) => getSettingsLoading || activateChannelLoading
    || deactivateChannelLoading || validateChannelLoading || verifyChannelLoading,
);

export const selectSettingsError = createAsyncActionErrorSelector(getSettingsRequest.type);
export const selectValidateChannelError = createSelector(
  createAsyncActionErrorSelector(validateChannelRequest.type),
  (error) => error?.message,
);
export const selectValidateChannelValidationError = createSelector(
  createAsyncActionErrorSelector(validateChannelRequest.type),
  (error) => (error?.type === ERROR_TYPE.VALIDATION ? error.message : undefined),
);
export const selectActivateChannelValidationError = createSelector(
  createAsyncActionErrorSelector(activateChannelRequest.type),
  (error) => (error?.type === ERROR_TYPE.VALIDATION ? error.message : undefined),
);
