import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from 'constants/routes';
import useAuthentication from 'hooks/useAuthentication';
import Login from 'pages/Login';
import ProcessList from 'pages/ProcessList';
import NotFoundPage from 'pages/NotFound';
import ErrorPage from 'pages/Error';
import TaskPage from 'pages/Task';
import ProcessInstanceList from 'pages/ProcessInstanceList';
import Forbidden from 'pages/Forbidden';
import StartForm from 'pages/StartForm';
import ProfilePage from 'pages/Profile';
import ProfileEditPage from 'pages/ProfileEdit';
import NotificationList from 'pages/NotificationList';
import { ROLES } from 'constants/roles';
import { X_PATH } from 'constants/xPath';
import {
  onboardingProcessRequest,
  selectOnboardingProcessStatusChecked,
} from 'store/onboarding';
import ProfileConfirmChannel from 'pages/ProfileConfirmChannel';
import Loader from '#web-components/components/Loader';
import PrivateRoute from './PrivateRoute';

const Routes: React.FC = () => {
  const dispatch = useDispatch();
  const { initialized, error, hasUnregisteredRealmRole } = useAuthentication();
  const isOnbordingStatusChecked = useSelector(selectOnboardingProcessStatusChecked);
  const isUnregistered = hasUnregisteredRealmRole;
  const showLoader = !initialized || (isUnregistered && !isOnbordingStatusChecked);

  useEffect(() => {
    if (isUnregistered) {
      dispatch(onboardingProcessRequest());
    }
  }, [dispatch, isUnregistered]);

  if (showLoader) {
    return <Loader show data-xpath={X_PATH.loader} />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <>
      <Loader show={false} data-xpath={X_PATH.loader} />
      <Switch>
        <Redirect from="/" to={ROUTES.PROCESS_LIST} exact />
        <Route path={ROUTES.LOGIN} component={Login} />
        <PrivateRoute path={ROUTES.PROCESS_LIST} component={ProcessList} roles={[ROLES.CITIZEN]} exact />
        <PrivateRoute path={ROUTES.NOTIFICATION_LIST} component={NotificationList} roles={[ROLES.CITIZEN]} exact />
        <PrivateRoute path={ROUTES.PROCESS_START_FORM} component={StartForm} roles={[ROLES.CITIZEN]} />
        <PrivateRoute path={ROUTES.USER_TASK} component={TaskPage} roles={[ROLES.CITIZEN]} />
        <PrivateRoute path={ROUTES.PROCESS_INSTANCE_LIST} component={ProcessInstanceList} roles={[ROLES.CITIZEN]} />
        <PrivateRoute path={ROUTES.PROFILE} component={ProfilePage} roles={[ROLES.CITIZEN]} exact />
        <PrivateRoute path={ROUTES.PROFILE_EDIT} component={ProfileEditPage} roles={[ROLES.CITIZEN]} />
        <PrivateRoute path={ROUTES.PROFILE_CONFIRM_CHANNEL} component={ProfileConfirmChannel} roles={[ROLES.CITIZEN]} />
        <PrivateRoute path={ROUTES.FORBIDDEN} component={Forbidden} roles={['*']} />
        <PrivateRoute path="*" component={NotFoundPage} roles={[ROLES.CITIZEN]} exact />
      </Switch>
    </>
  );
};

export default Routes;
