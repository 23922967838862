import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NotificationsSystem, {
  dismissNotification,
  setUpNotifications,
  POSITIONS,
  Notification,
} from 'reapop';
import Box from '@material-ui/core/Box';
import { selectNotifications } from 'store/notifications';
import FlashMessage, { ViewType, theme as notificationTheme } from '#web-components/components/FlashMessage';
import Modal from '#web-components/components/Modal';
import Typography from '#web-components/components/Typography';
import ErrorInfoBox from '#web-components/components/ErrorInfoBox';

setUpNotifications({
  defaultProps: {
    position: POSITIONS.topRight,
    dismissAfter: 8000,
  },
});

const Notifications: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [currentNotification, setCurrentNotification] = useState<Notification | null>(null);
  const notifications = useSelector(selectNotifications);

  const handleNotification = useCallback((id: string) => {
    dispatch(dismissNotification(id));
  }, [dispatch]);

  const handleClose = useCallback((id: string) => () => {
    dispatch(dismissNotification(id));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleOpenModal = useCallback((id: string) => () => {
    const notification = notifications.find((n: Notification) => n.id === id);
    setCurrentNotification(notification || null);
    setModalOpen(true);
    handleClose(id)();
  }, [handleClose, notifications]);

  // TODO: Declare this component outside parent component "Notifications" or memoize it
  // eslint-disable-next-line react/no-unstable-nested-components
  function NotificationComponent({ notification }: { notification: Notification }) {
    return (
      <FlashMessage
        status={notification.status}
        title={notification.title}
        message={notification.message}
        onClose={handleClose(notification.id)}
        viewType={ViewType.notification}
        hasButton={notification.status === 'error'}
        buttonTitle={t('errors.notification.helpButton')}
        buttonHandler={handleOpenModal(notification.id)}
      />
    );
  }

  return (
    <>
      <NotificationsSystem
        notifications={notifications}
        dismissNotification={handleNotification}
        theme={notificationTheme}
        components={{
          Notification: NotificationComponent,
        }}
      />
      <Modal
        isOpen={isModalOpen}
        cancelText={t('close')}
        onOpenChange={handleCloseModal}
        title={t('errors.notification.errorModal.title')}
        scroll="body"
        hasCloseBtn
      >
        <Box mt={6}>
          <Typography variant="bodyText">{t('errors.notification.errorModal.writeMail')}</Typography>
        </Box>
        <Box mt={1}>
          <ErrorInfoBox>
            <Typography variant="h6">{REGISTRY_ENVIRONMENT_VARIABLES.supportEmail}</Typography>
          </ErrorInfoBox>
        </Box>
        <Box mt={4}>
          <Typography variant="bodyText">{t('errors.notification.errorModal.copyTraceId')}</Typography>
        </Box>
        <Box mt={1}>
          <ErrorInfoBox>
            <Typography variant="smallText">
              {
                currentNotification?.traceId
                  ? `Trace ID: ${currentNotification?.traceId}`
                  : t('errors.notification.errorModal.emptyTraceId')
              }
            </Typography>
          </ErrorInfoBox>
        </Box>
        <Box mt={4}>
          <Typography variant="bodyText">{t('errors.notification.errorModal.description')}</Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="bodyText">{t('errors.notification.errorModal.thanks')}</Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Notifications;
