import { PROCESS_MANAGEMENT_API_APPENDIX } from 'constants/baseUrl';
import { ENTITIES } from 'constants/entities';

import { api } from '.';

export const getProcessListDefinitions = () => {
  return api.get$(`${PROCESS_MANAGEMENT_API_APPENDIX}/${ENTITIES.PROCESS_DEFINITION}/`);
};

export const getGroupedProcessListDefinitions = () => {
  return api.get$(`${PROCESS_MANAGEMENT_API_APPENDIX}/grouped-${ENTITIES.PROCESS_DEFINITION}`);
};

export const startProcess = (processKey: string) => {
  return api
    .post$(`${PROCESS_MANAGEMENT_API_APPENDIX}/${ENTITIES.PROCESS_DEFINITION}/${processKey}/start`, {});
};

export const startProcessWithForm = (processKey: string, formData: Record<string, unknown>) => {
  return api
    .post$(`${PROCESS_MANAGEMENT_API_APPENDIX}/${ENTITIES.PROCESS_DEFINITION}/${processKey}/start-with-form`, formData);
};

export const getProcessDefinitionByKey = (key: string) => {
  return api.get$(`${PROCESS_MANAGEMENT_API_APPENDIX}/${ENTITIES.PROCESS_DEFINITION}/${key}`);
};
