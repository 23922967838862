import React from 'react';
import { useSelector } from 'react-redux';

import { ERROR_TYPE, ErrorInfo } from '#shared/types/common';
import { Navbar } from 'components/Navbar';
import { selectorAsyncActionCriticalErrors } from 'store/asyncAction/selectors';
import StandardLayoutCommon from '#web-components/components/Layouts/Standard';
import { getRegistryTitle, getRegistryTitleFull } from '#shared/utils/registrySettings';
import ErrorLayout from '../Error';

interface Props {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  isLoading?: boolean;
  disableBackground?: boolean;
  error?: ErrorInfo;
}

export default function StandardLayout({
  title,
  description,
  disableBackground,
  error,
  isLoading = false,
  children,
}: Props) {
  const criticalError = useSelector(selectorAsyncActionCriticalErrors);
  const hasError = criticalError || (error && (!error.type || error.type === ERROR_TYPE.CRITICAL));
  const registryTitle = getRegistryTitle(REGISTRY_SETTINGS);
  const registryTitleFull = getRegistryTitleFull(REGISTRY_SETTINGS);

  if (hasError) {
    return (
      <ErrorLayout error={criticalError || error} />
    );
  }

  return (
    <StandardLayoutCommon
      disableBackground={disableBackground}
      title={title}
      registryName={registryTitle}
      registryFullName={registryTitleFull}
      description={description}
      isLoading={isLoading}
      navbar={<Navbar disableBackground={disableBackground} />}
    >
      {children}
    </StandardLayoutCommon>
  );
}
