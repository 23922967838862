import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { cleanAsyncActionState } from 'store/asyncAction/slice';

type ErrorNavLinkProps = {
  to: string,
  title: string,
  className?: string;
  children?: React.ReactNode,
};

export default function ErrorNavLink({
  to, title, className, children,
}: ErrorNavLinkProps) {
  const dispatch = useDispatch();
  const handleBackLinkClick = useCallback(() => {
    dispatch(cleanAsyncActionState());
  }, [dispatch]);

  return (
    <Link
      to={to}
      title={title}
      onClick={handleBackLinkClick}
      className={className}
    >
      {children}
    </Link>
  );
}
