import { ActionsObservable } from 'redux-observable';
import { Action, ActionCreator } from '@reduxjs/toolkit';
import { of } from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import { push } from 'connected-react-router';
import * as processDefinitionApi from 'api/processDefinition';
import * as processInstanceApi from 'api/processInstance';
import { ROUTES, TAB_ROUTES } from 'constants/routes';
import { getProcessInstancesError } from 'store/processInstances';
import { getPendingTaskRequest } from 'store/userTask';
import {
  getProcessDefinitionsError,
  startProcessRequest,
} from 'store/processDefinitions';
import { ErrorInfo } from '#shared/types/common';
import { getCriticalErrorProps, catchError } from '#shared/utils/apiHelpers';
import {
  onboardingProcessRequest,
  onboardingProcessRequestSuccess,
  onboardingProcessRequestError,
} from './slice';
import { ONBOARDING_PROCESS_FILTER_PARAMS } from '../../constants/tableFiltering';

function getResultObservable<T>({
  processAction,
  payload,
  isProcessError = false,
}: { processAction: ActionCreator<{ type: string }>, payload: T, isProcessError?: boolean }) {
  return of(
    processAction(payload),
    isProcessError ? onboardingProcessRequestError(payload) : onboardingProcessRequestSuccess(),
  );
}

function getResultObservableAndRoute(route: string) {
  return of(onboardingProcessRequestSuccess(), push(route));
}

export const onboardingProcessEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$
    .pipe(
      filter(onboardingProcessRequest.match),
      mergeMap(() => {
        return processDefinitionApi.getProcessListDefinitions().pipe(
          mergeMap((processDefinitionList) => {
            const resProcessDefinitionList = processDefinitionList.response;

            if (resProcessDefinitionList.length === 1) {
              return processInstanceApi.getProcessInstancesList(ONBOARDING_PROCESS_FILTER_PARAMS).pipe(
                mergeMap((processInstanceList) => {
                  const resProcessInstanceList = processInstanceList.response;
                  const processDefinitionKey = resProcessDefinitionList[0].key;
                  const processDefinitionFormKey = resProcessDefinitionList[0].formKey;

                  if (resProcessInstanceList.length === 1) {
                    const { processInstanceId } = resProcessInstanceList[0];

                    return getResultObservable<{ processInstanceId: string }>({
                      processAction: getPendingTaskRequest,
                      payload: { processInstanceId },
                    });
                  }

                  if (resProcessInstanceList.length === 0) {
                    if (processDefinitionFormKey) {
                      return getResultObservableAndRoute(
                        ROUTES.PROCESS_START_FORM.replace(':processDefinitionKey', processDefinitionKey),
                      );
                    }
                    return getResultObservable<string>({
                      processAction: startProcessRequest,
                      payload: processDefinitionKey,
                    });
                  }

                  return getResultObservableAndRoute(TAB_ROUTES.PROCESS_INSTANCE_LIST_ACTIVE);
                }),
                catchError((serverResponse) => getResultObservable<ErrorInfo>({
                  processAction: getProcessInstancesError,
                  payload: getCriticalErrorProps({ serverResponse }),
                  isProcessError: true,
                })),
              );
            }
            return getResultObservableAndRoute(ROUTES.PROCESS_LIST);
          }),
          catchError((serverResponse) => getResultObservable<ErrorInfo>({
            processAction: getProcessDefinitionsError,
            payload: getCriticalErrorProps({ serverResponse }),
            isProcessError: true,
          })),
        );
      }),
    );
};
