import common from './common.json';
import formDomain from './domainSpecific/formDomain.json';
import confirmChannelPage from './pageSpecific/confirmChannelPage.json';
import loginPage from './pageSpecific/loginPage.json';
import processListPage from './pageSpecific/processListPage.json';
import taskPage from './pageSpecific/taskPage.json';
import processInstanceListPage from './pageSpecific/processInstanceListPage.json';
import startFormPage from './pageSpecific/startFormPage.json';
import profilePage from './pageSpecific/profilePage.json';
import notificationListPage from './pageSpecific/notificationListPage.json';

export const UA = {
  common,
  formDomain,
  confirmChannelPage,
  loginPage,
  processListPage,
  taskPage,
  processInstanceListPage,
  startFormPage,
  profilePage,
  notificationListPage,
};
