import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { ErrorInfo } from '#shared/types/common';
import { ROUTES } from 'constants/routes';
import { rateLimitCriticalErrorProps } from 'constants/errorProps';
import ErrorLayoutCommon from '#web-components/components/Layouts/Error';
import { getRegistryTitle, getRegistryTitleFull } from '#shared/utils/registrySettings';
import ErrorNavLink from './components/ErrorNavLink';

type ErrorLayoutProps = {
  error: ErrorInfo
};

export default function ErrorLayout({ error: errorProps }: ErrorLayoutProps) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const registryTitle = getRegistryTitle(REGISTRY_SETTINGS);
  const registryTitleFull = getRegistryTitleFull(REGISTRY_SETTINGS);

  const handleBackLinkClick = useCallback((path) => {
    dispatch(push(path));
  }, [dispatch]);

  let error = errorProps;

  const systemError = useMemo(() => {
    if (!error.isSystemError) {
      return undefined;
    }

    const errorInfoFields = [{
      name: t('components.errorLayout.pageUrl'),
      value: window.location.href,
    }];

    if (error.traceId) {
      errorInfoFields.unshift({
        name: t('components.errorLayout.traceId'),
        value: error.traceId,
      });
    }
    return {
      title: t('components.errorLayout.systemErrorDescription'),
      fields: errorInfoFields,
    };
  }, [error.isSystemError, error.traceId, t]);

  if (error.httpStatus === 429) {
    error = rateLimitCriticalErrorProps;
  }

  return (
    <ErrorLayoutCommon
      registryName={registryTitle}
      registryFullName={registryTitleFull}
      error={error}
      reloadButtonCaption={t('components.errorLayout.reloadPage')}
      defaultErrorTitle={t('components.errorLayout.errorTitle')}
      defaultBackLinkTitle={t('components.errorLayout.backToHome')}
      defaultMessage={t('components.errorLayout.smthWentWrong')}
      defaultDescription={t('components.errorLayout.reloadPageAndContactAdministrator')}
      homePath={ROUTES.PROCESS_LIST}
      navLinkComponent={ErrorNavLink}
      appTitle={t('appTitle')}
      onBackLinkClick={handleBackLinkClick}
      systemError={systemError}
      supportEmail={{
        email: REGISTRY_ENVIRONMENT_VARIABLES.supportEmail,
        subject: t('errors.supportEmail.subject'),
        body: t('errors.supportEmail.body'),
      }}
      sideBarContent={{
        title: t('components.errorLayout.needHelp'),
        description: t('components.errorLayout.copySystemErrorDescription'),
      }}
    />
  );
}
