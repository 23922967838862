import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { RootState } from 'store/rootReducer';
import {
  selectProcessDefinitionsIsLoading,
  selectProcessDefinitionsError,
  selectGroupedProcessDefinitions,
  getGroupedProcessDefinitionsRequest,
  startProcessRequest,
} from 'store/processDefinitions';
import { ProcessDefinitionGroupData } from '#shared/types/processDefinition';
import { ErrorInfo } from '#shared/types/common';
import ProcessListPage from './ProcessListPage';
import styles from './ProcessListPage.styles';

export interface PropsFromState {
  processGroupData: ProcessDefinitionGroupData;
  isLoading: boolean;
  error?: ErrorInfo
}

export interface DispatchProps {
  getProcessGroupData: typeof getGroupedProcessDefinitionsRequest;
  startProcess: typeof startProcessRequest;
}

function mapStateToProps(state: RootState): PropsFromState {
  return {
    processGroupData: selectGroupedProcessDefinitions(state),
    isLoading: selectProcessDefinitionsIsLoading(state),
    error: selectProcessDefinitionsError(state),
  };
}

const mapDispatchToProps: DispatchProps = {
  getProcessGroupData: getGroupedProcessDefinitionsRequest,
  startProcess: startProcessRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation('processListPage')(
    withRouter(
      withStyles(styles)(ProcessListPage),
    ),
  ),
);
