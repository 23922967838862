import { HistoryFilterOptions, ListFilterOptions } from '#shared/types/common';
import { Order } from '#web-components/types/table';

export const ALL_TASKS_FILTER_PARAMS: ListFilterOptions = {
  sortBy: 'id',
  sortOrder: Order.desc,
  firstResult: 0,
  maxResults: 100000,
};

export const ONBOARDING_PROCESS_FILTER_PARAMS: HistoryFilterOptions = {
  offset: 0,
  limit: 2,
  sort: 'desc(startTime)',
};
