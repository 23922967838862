import { combineReducers, Reducer } from 'redux';
import { History } from 'history';
import { connectRouter, RouterState } from 'connected-react-router';
import { Notification, reducer as notificationsReducer } from 'reapop';
import CurrentUserReducer, { CurrentUserState } from './currentUser';
import UserTaskReducer, { UserTaskState } from './userTask';

import AsyncActionReducer, { AsyncActionState } from './asyncAction';
import ProcessDefinitionsReducer, { ProcessDefinitionsState } from './processDefinitions';
import ProcessInstancesReducer, { ProcessInstancesState } from './processInstances';
import ProcessInstancesHistoryReducer, { ProcessInstancesHistoryState } from './processInstancesHistory';
import OnboardingProcessReducer, { OnboardingProcessState } from './onboarding';
import SettingsReducer, { SettingsState } from './settings';
import userNotificationsReducer, { UserNotificationsState } from './userNotifications';

export interface RootState {
  asyncAction: AsyncActionState;
  router: RouterState;
  currentUser: CurrentUserState;
  notifications: Notification[];
  userTask: UserTaskState;
  processDefinitions: ProcessDefinitionsState;
  processInstances: ProcessInstancesState;
  processInstancesHistory: ProcessInstancesHistoryState;
  onboardingProcess: OnboardingProcessState;
  settings: SettingsState;
  userNotifications: UserNotificationsState;
}

export const rootReducer = (history: History): Reducer<RootState> => combineReducers({
  router: connectRouter(history),
  asyncAction: AsyncActionReducer,
  currentUser: CurrentUserReducer,
  notifications: notificationsReducer(),
  processDefinitions: ProcessDefinitionsReducer,
  userTask: UserTaskReducer,
  processInstances: ProcessInstancesReducer,
  processInstancesHistory: ProcessInstancesHistoryReducer,
  onboardingProcess: OnboardingProcessReducer,
  settings: SettingsReducer,
  userNotifications: userNotificationsReducer,
});
