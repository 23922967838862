import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { API_URL, EXCERPTS_API_APPENDIX } from 'constants/baseUrl';
import { X_PATH } from 'constants/xPath';
import Button, { ButtonVariants } from '#web-components/components/Button';
import styles from './EndedProcessInstanceTableAction.styles';

interface EndedProcessInstanceTableActionProps {
  excerptId: string,
}

const useStyles = makeStyles(styles, { name: 'EndedProcessInstanceTableAction' });

export default function EndedProcessInstanceTableAction({ excerptId }: EndedProcessInstanceTableActionProps) {
  const classes = useStyles();
  const { t } = useTranslation('processInstanceListPage');

  const handleClick = useCallback(() => {
    const url = `${API_URL}/${EXCERPTS_API_APPENDIX}/${excerptId}`;
    const link = document.createElement('a');
    link.download = excerptId;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [excerptId]);

  return (
    <Button
      variant={ButtonVariants.secondary}
      size="small"
      className={classes.button}
      data-xpath={X_PATH.downloadExcerpt}
      onClick={handleClick}
    >
      {t('components.processInstanceTable.button.dowloadExcerpt')}
    </Button>
  );
}
