import { ActionsObservable } from 'redux-observable';
import { Action } from '@reduxjs/toolkit';
import { forkJoin, of } from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';

import * as historyApi from 'api/history';
import { tryAgainNotificationErrorProps } from 'constants/errorProps';
import { getCriticalErrorProps, catchError, getNotificationErrorProps } from '#shared/utils/apiHelpers';

import {
  getProcessInstancesHistoryRequest,
  getProcessInstancesHistorySuccess,
  getProcessInstancesHistoryError,
} from './slice';

export const getProcessInstancesHistoryEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$
    .pipe(
      filter(getProcessInstancesHistoryRequest.match),
      mergeMap(({ payload }) => {
        const { filterParams, isInitialRequest } = payload;
        const {
          page,
          rowsPerPage,
          order,
          orderField,
        } = filterParams;
        const firstResult = page * rowsPerPage;
        const arrApi = [
          historyApi.getProcessInstancesHistory({
            offset: firstResult,
            limit: rowsPerPage + 1,
            sort: `${order}(${orderField})`,
          }),
        ];
        return forkJoin(arrApi).pipe(
          mergeMap((response) => {
            const [processList] = response;
            return of(
              getProcessInstancesHistorySuccess({
                list: processList.response,
                filterParams,
              }),
            );
          }),
          catchError((serverResponse) => of(getProcessInstancesHistoryError(
            isInitialRequest
              ? getCriticalErrorProps({ serverResponse })
              : getNotificationErrorProps(serverResponse.response, tryAgainNotificationErrorProps),
          ))),
        );
      }),
    );
};
