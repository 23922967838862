import { createStyles, Theme } from '@material-ui/core';
import { spacing, h8 } from '#web-components/styles';

const styles = ({ colors }: Theme) => createStyles({
  root: {
    marginLeft: 'auto',
    marginRight: spacing * 4,
  },
  link: {
    ...h8,
    color: colors.textMainPrimary,
    margin: spacing * 2,
    paddingBottom: 2,
    textDecoration: 'none',
    '&:hover': {
      borderBottom: `2px solid ${colors.uIBase}`,
    },
  },
  active: {
    borderBottom: `2px solid ${colors.uIBase}`,
  },
});

export default styles;
