import { ProcessInstanceListTabs, TAB_QUERY_PARAM } from './tabs';

export const ROUTES = {
  LOGIN: '/login',
  PROCESS_LIST: '/process-list',
  PROCESS_START_FORM: '/process-list/:processDefinitionKey/start-form',
  FORBIDDEN: '/forbidden',
  USER_TASK: '/user-tasks-list/:taskId',
  PROCESS_INSTANCE_LIST: '/process-instance-list',
  LOGOUT: '/login?logout',
  PROFILE: '/profile',
  PROFILE_EDIT: '/profile/edit',
  PROFILE_CONFIRM_CHANNEL: '/profile/:channel/confirm',
  NOTIFICATION_LIST: '/notification-list',
};

export const TAB_ROUTES = {
  PROCESS_INSTANCE_LIST_ACTIVE: `${ROUTES.PROCESS_INSTANCE_LIST}?${TAB_QUERY_PARAM}=${ProcessInstanceListTabs.active}`,
  PROCESS_INSTANCE_LIST_ENDED: `${ROUTES.PROCESS_INSTANCE_LIST}?${TAB_QUERY_PARAM}=${ProcessInstanceListTabs.ended}`,
};
