import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import Menu from 'components/Menu';
import { ROUTES } from 'constants/routes';
import HomeButton from '#web-components/components/Navbar/components/HomeButton';
import NavbarContainer from '#web-components/components/Navbar';
import styles from './Navbar.styles';
import HeaderLinks from './components/HeaderLinks';

const useStyles = makeStyles(styles, { name: 'Navbar' });

interface NavBarProps {
  disableBackground?: boolean
}

const Navbar: React.FC<NavBarProps> = ({ disableBackground }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  return (
    <NavbarContainer disableBackground={disableBackground}>
      <Box className={classes.box}>
        <HomeButton
          fullWidth
          direction="left"
          homePath={ROUTES.PROCESS_LIST}
          appTitle={t('appTitle')}
          navLinkComponent={RouterLink}
        />
        <HeaderLinks />
        <Menu />
      </Box>
    </NavbarContainer>
  );
};

export default Navbar;
