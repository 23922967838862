import { createStyles } from '@material-ui/styles';
import { spacing } from '#web-components/styles';

const styles = () => createStyles({
  buttonContainer: {
    display: 'flex',
    marginTop: 10 * spacing,
    '& > *': {
      marginRight: 3 * spacing,
    },
  },
  root: {
    marginTop: spacing * 6,
  },
  sidebarTitle: {
    marginBottom: spacing + 4,
  },
});

export default styles;
