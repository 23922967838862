import { Action } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import {
  concatMap,
  filter,
  map,
  mergeMap,
} from 'rxjs/operators';
import { push } from 'connected-react-router';
import { notify } from 'reapop';

import * as api from 'api/settings';
import { tryAgainNotificationErrorProps } from 'constants/errorProps';
import i18n from 'localization';
import { ChannelType } from 'types/settings';
import { ROUTES } from 'constants/routes';
import { ERROR_TYPE } from '#shared/types/common';

import { catchError, getNotificationErrorProps } from '#shared/utils/apiHelpers';

import {
  getSettingsRequest,
  getSettingsSuccess,
  getSettingsError,
  activateChannelSuccess,
  activateChannelRequest,
  activateChannelError,
  validateChannelRequest,
  validateChannelSuccess,
  validateChannelError,
  deactivateChannelRequest,
  deactivateChannelSuccess,
  deactivateChannelError,
  verifyChannelRequest,
  verifyChannelSuccess,
  verifyChannelError,
} from './slice';

export const getUserSettingsEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$.pipe(
    filter(getSettingsRequest.match),
    mergeMap(() => {
      return api.getSettings().pipe(
        map(({ response }) => getSettingsSuccess(response)),
        catchError((error) => of(getSettingsError({ httpStatus: error.status }))),
      );
    }),
  );
};

export const activateChannelEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$.pipe(
    filter(activateChannelRequest.match),
    mergeMap((action) => {
      const { channel, address, verificationCode } = action.payload;
      return api.activateChannel(channel, { address, verificationCode }).pipe(
        mergeMap(() => of(
          activateChannelSuccess(),
          getSettingsRequest(),
          push(ROUTES.PROFILE),
          notify(
            i18n.t(channel === ChannelType.EMAIL
              ? 'profilePage:edit.notifications.activatedEmailSuccess'
              : 'profilePage:edit.notifications.activatedDiiaSuccess'),
            'success',
          ),
        )),
        catchError((error) => {
          if (error.status === 400) {
            return of(activateChannelError({
              type: ERROR_TYPE.VALIDATION,
              message: i18n.t('profilePage:edit.notifications.activatedEmailFail'),
            }));
          }

          return of(activateChannelError({
            message: i18n.t('errors.notification.tryAgainLater.message'),
            type: ERROR_TYPE.NOTIFICATION,
            traceId: error.response?.traceId,
          }));
        }),
      );
    }),
  );
};

export const deactivateChannelEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$.pipe(
    filter(deactivateChannelRequest.match),
    mergeMap((action) => {
      const { channel } = action.payload;
      return api.deactivateChannel(channel).pipe(
        mergeMap(() => of(
          deactivateChannelSuccess(),
          getSettingsRequest(),
          notify(
            i18n.t(channel === ChannelType.EMAIL
              ? 'profilePage:edit.notifications.deactivatedEmailSuccess'
              : 'profilePage:edit.notifications.deactivatedDiiaSuccess'),
            'success',
          ),
        )),
        catchError((serverResponse) => of(deactivateChannelError(
          getNotificationErrorProps(serverResponse.response, tryAgainNotificationErrorProps),
        ))),
      );
    }),
  );
};

export const validateChannelEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$.pipe(
    filter(validateChannelRequest.match),
    mergeMap((action) => {
      const { channel, address } = action.payload;
      return api.validateChannel(channel, { address }).pipe(
        concatMap(() => {
          if (channel === ChannelType.EMAIL) {
            return of(
              validateChannelSuccess(),
              push(`${ROUTES.PROFILE_CONFIRM_CHANNEL.replace(':channel', ChannelType.EMAIL)}?address=${address}`),
            );
          }

          return of(
            validateChannelSuccess(),
          );
        }),
        catchError(({ response }) => of(validateChannelError(
          response?.localizedMessage
            ? { message: response.localizedMessage, type: ERROR_TYPE.VALIDATION, traceId: response?.traceId }
            : {
              message: i18n.t('errors.notification.tryAgainLater.message'),
              type: ERROR_TYPE.NOTIFICATION,
              traceId: response?.traceId,
            },
        ))),
      );
    }),
  );
};

export const verifyChannelEpic = (
  action$: ActionsObservable<Action>,
) => {
  return action$.pipe(
    filter(verifyChannelRequest.match),
    mergeMap((action) => {
      const { channel, address } = action.payload;
      return api.verifyChannel(channel, { address }).pipe(
        concatMap((response) => of(
          verifyChannelSuccess({ expirationTime: response.response.verificationCodeExpirationSec || 0 }),
        )),
        catchError(({ response }) => of(verifyChannelError(
          response?.localizedMessage
            ? { message: response.localizedMessage, type: ERROR_TYPE.NOTIFICATION, traceId: response?.traceId }
            : {
              message: i18n.t('errors.notification.tryAgainLater.message'),
              type: ERROR_TYPE.NOTIFICATION,
              traceId: response?.traceId,
            },
        ))),
      );
    }),
  );
};
