import { createStyles } from '@material-ui/styles';
import { spacing } from '#web-components/styles';

const styles = () => createStyles({
  title: {
    marginTop: spacing * 4,
    marginBottom: spacing * 3,
  },
  btnGroup: {
    display: 'grid',
    marginTop: spacing * 6,
    gridTemplateColumns: 'auto auto',
    gridGap: spacing * 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  prohibitedIcon: {
    lineHeight: `${spacing * 7 + 1}px`,
  },
});

export default styles;
