import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProcessInstanceListItem } from '#shared/types/processInstance';
import { ErrorInfo } from '#shared/types/common';
import { FilterParams, Order } from '#web-components/types/table';
import { ROWS_PER_PAGE_OPTIONS } from '#web-components/components/Table';

const ACTION_PREFIX = 'processInstances';

export interface ProcessInstancesState {
  list: Array<ProcessInstanceListItem>;
  filterParams: FilterParams;
}

const initialState: ProcessInstancesState = {
  list: [],
  filterParams: {
    page: 0,
    rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    order: Order.desc,
    orderField: 'startTime',
  },
};

export const getProcessInstancesRequest = createAction<{
  filterParams: FilterParams, isInitialRequest?: boolean
}>(`${ACTION_PREFIX}/getProcessInstancesRequest`);
export const getProcessInstancesError = createAction<ErrorInfo>(`${ACTION_PREFIX}/getProcessInstancesError`);

const ProcessInstancesSlice = createSlice({
  name: ACTION_PREFIX,
  initialState,
  reducers: {
    getProcessInstancesSuccess(state, action: PayloadAction<{
      list: Array<ProcessInstanceListItem>,
      filterParams: FilterParams,
    }>) {
      const { list, filterParams } = action.payload;
      return {
        ...state,
        list,
        filterParams,
      };
    },
    getProcessInstancesClean(state) {
      return {
        ...state,
        list: [],
        filterParams: initialState.filterParams,
      };
    },
  },
});

export const {
  getProcessInstancesSuccess,
  getProcessInstancesClean,
} = ProcessInstancesSlice.actions;
export default ProcessInstancesSlice.reducer;
