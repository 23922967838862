export const X_PATH = {
  /* header */
  header: 'header',
  headerUserInfo: 'headerUserInfo',
  logout: 'logoutButton',

  /* Login page */
  loginButton: 'loginButton',

  /* Task page */
  validationMessage: 'validationMessage',
  validationMessageTitle: 'validationMessageTitle',
  changeKeyButton: 'changeKeyButton',
  signButton: 'signButton',

  /* other */
  loader: 'loader',
  /* table */
  tableFirstPage: 'tableFirstPage',
  tableNextPage: 'tableNextPage',
  tablePrevPage: 'tablePrevPage',
  tableLastPage: 'tableLastPage',
  tablePageCountSelect: 'tablePageCountSelect',
  tableContainer: 'tableContainer',
  tableRow: 'tableRow',
  tableHeadCell: 'tableHeadCell',

  /* main page */
  processActiveOption: 'processActiveMenuOption',

  /* other */
  tabsButton: 'tabsButton',
  downloadExcerpt: 'downloadExcerpt',

  /* Process Definitions page */
  backToGroups: 'backToGroups',
};
