import { TASK_MANAGEMENT_API_APPENDIX } from 'constants/baseUrl';
import { ENTITIES } from 'constants/entities';
import { TaskSubmissionPayload, SignTaskPayload } from '#shared/types/task';
import { ListFilterOptions } from '#shared/types/common';
import { buildURLQuery } from '#shared/utils/apiHelpers';

import { api } from '.';

export const getTaskById = (id: string) => {
  return api.get$(`${TASK_MANAGEMENT_API_APPENDIX}/${ENTITIES.TASK}/${id}`);
};

export const completeTask = (taskId: string, payload: TaskSubmissionPayload) => {
  return api.post$(`${TASK_MANAGEMENT_API_APPENDIX}/${ENTITIES.TASK}/${taskId}/complete`, payload);
};

export const completeSignTask = (taskId: string, payload: SignTaskPayload) => {
  return api.post$(`${TASK_MANAGEMENT_API_APPENDIX}/citizen/${ENTITIES.TASK}/${taskId}/sign-form`, payload);
};

export const getPendingTasksList = (requestParams: ListFilterOptions, processInstanceId?: string) => {
  const urlQuery = processInstanceId
    ? buildURLQuery({ ...requestParams, processInstanceId })
    : buildURLQuery(requestParams);
  return api.get$(`${TASK_MANAGEMENT_API_APPENDIX}/${ENTITIES.TASK}?${urlQuery}`);
};

export const getPendingTaskIdsList = (requestParams: ListFilterOptions, rootProcessInstanceId: string) => {
  const urlQuery = buildURLQuery({ ...requestParams, rootProcessInstanceId });
  return api.get$(`${TASK_MANAGEMENT_API_APPENDIX}/${ENTITIES.TASK}/lightweight?${urlQuery}`);
};

export const saveTask = (taskId: string, payload: TaskSubmissionPayload) => {
  return api.post$(`${TASK_MANAGEMENT_API_APPENDIX}/${ENTITIES.TASK}/${taskId}/save`, payload);
};
