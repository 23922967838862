import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';

import Modal from '#web-components/components/Modal';
import { ProhibitedIcon } from '#web-components/components/Icons';
import Typography from '#web-components/components/Typography';
import Button, { ButtonVariants } from '#web-components/components/Button';

import styles from './ModalDiactivateChannel.styles';

interface ModalDiactivateChannelProps {
  isOpen: boolean,
  onOpenChange: (isOpen: boolean) => void,
  onSubmit: () => void,
  channel?: string,
}

const useStyles = makeStyles(styles, { name: 'ModalDiactivateChannel' });

export default function ModalDiactivateChannel(
  {
    channel,
    isOpen,
    onOpenChange,
    onSubmit,
  }: ModalDiactivateChannelProps,
) {
  const { t } = useTranslation('profilePage');
  const classes = useStyles();
  return (
    <Modal
      isPopUp
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      scroll="body"
    >
      <ProhibitedIcon size={56} className={classes.prohibitedIcon} />
      <Typography variant="h2" className={classes.title}>{t('settings.turnOffMessage')}?</Typography>
      <Typography variant="bodyText">{channel}</Typography>
      <Box className={classes.btnGroup}>
        <Button onClick={() => onOpenChange(false)}>
          {t('settings.no')}
        </Button>
        <Button variant={ButtonVariants.secondary} onClick={onSubmit}>
          {t('settings.yes')}
        </Button>
      </Box>
    </Modal>
  );
}
