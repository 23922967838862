import {
  createAsyncActionIsLoadedSelector,
  createAsyncActionIsLoadingSelector,
  createAsyncActionErrorSelector,
} from '../asyncAction';
import type { RootState } from '../rootReducer';
import {
  getProcessInstancesRequest, ProcessInstancesState,
} from './slice';

export const selectProcessInstancesState = (state: RootState): ProcessInstancesState => state.processInstances;

export const selectProcessInstances = (state: RootState) => state.processInstances.list;
export const selectProcessInstancesIsLoading = createAsyncActionIsLoadingSelector(getProcessInstancesRequest.type);
export const selectProcessInstancesIsLoaded = createAsyncActionIsLoadedSelector(getProcessInstancesRequest.type);

export const selectProcessInstancesFilterParams = (
  state: RootState,
) => state.processInstances.filterParams;

export const selectProcessInstancesError = (
  createAsyncActionErrorSelector(getProcessInstancesRequest.type)
);
