import React, { Component } from 'react';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { WithStyles } from '@material-ui/core';
import memoize from 'lodash/memoize';

import { ProcessDefinition, ProcessDefinitionGroup } from '#shared/types/processDefinition';
import GroupedEntityList from '#web-components/components/GroupedEntityList';
import { ListItem } from '#web-components/types/table';
import { EntityGroup } from '#web-components/types/groupedEntity';
import { MenuEmptyListPlaceholder } from '#web-components/components/MenuPanel';

import StandardLayout from 'components/Layouts/Standard';
import CriticalError from 'components/CriticalError';
import { ROUTES } from 'constants/routes';
import { X_PATH } from 'constants/xPath';

import type { PropsFromState, DispatchProps } from './index';
import styles from './ProcessListPage.styles';

interface Props extends PropsFromState, DispatchProps, WithTranslation, WithStyles<typeof styles>, RouteComponentProps {
}

class ProcessListPage extends Component<Props> {
  componentDidMount() {
    const { getProcessGroupData } = this.props;
    getProcessGroupData();
  }

  getGroups = memoize((groups: Array<ProcessDefinitionGroup>): Array<EntityGroup> => {
    return groups.map((group) => ({
      ...group,
      entityList: group.processDefinitions,
    }));
  });

  onProcessDefinitionClick = (item: ListItem) => {
    const process = item as ProcessDefinition;
    const { startProcess, history } = this.props;
    if (process.formKey) {
      return history.push(ROUTES.PROCESS_START_FORM.replace(':processDefinitionKey', process.key));
    }
    return startProcess(process.key);
  };

  renderProcessList = () => {
    const {
      processGroupData,
      error,
      isLoading,
      t,
    } = this.props;
    const convertedGroupList = this.getGroups(processGroupData.groups);
    const processListEmpty = !processGroupData.groups.length && !processGroupData.ungrouped.length;

    if (error) {
      return <CriticalError error={error} />;
    }

    if (!isLoading && processListEmpty) {
      return (
        <MenuEmptyListPlaceholder
          message={t('processList.emptyPlaceholder.message')}
          description={t('processList.emptyPlaceholder.description')}
        />
      );
    }

    return (
      <GroupedEntityList
        groupList={convertedGroupList}
        ungroupedList={processGroupData.ungrouped}
        onEntitySelect={this.onProcessDefinitionClick}
        readonly
        data-xpath={{
          backToGroups: X_PATH.backToGroups,
        }}
        localization={{
          groupsTitle: t('processGroups.groupsTitle'),
          ungroupsTitle: t('processGroups.ungroupsTitle'),
          emptyGroupsPlaceholder: t('processGroups.emptyGroupsPlaceholder'),
          emptyCurrentGroupPlaceholder: t('processGroups.emptyCurrentGroupPlaceholder'),
        }}
      />
    );
  };

  render() {
    const { isLoading, t } = this.props;
    return (
      <StandardLayout
        title={t('common:appSections.processDefinition.title')}
        description={t('common:appSections.processDefinition.description')}
        isLoading={isLoading}
      >
        {this.renderProcessList()}
      </StandardLayout>
    );
  }
}

export default ProcessListPage;
