import { combineEpics } from 'redux-observable';
import { Action } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';

import { getUserInfoEpic, userLoginEpic } from './currentUser';
import {
  completeSignTaskEpic, completeTaskEpic, getFormByTaskIdEpic, getPendingTaskEpic, saveTaskEpic,
} from './userTask';
import { handleNonCriticalErrorsEpic } from './asyncAction';
import { getProcessInstancesEpic } from './processInstances';
import {
  getFormByProcessDefinitionKeyEpic,
  getProcessDefinitionsEpic,
  getGroupedProcessDefinitionsEpic,
  startProcessEpic,
  startProcessWithFormEpic,
} from './processDefinitions';
import { getProcessInstancesHistoryEpic } from './processInstancesHistory';
import { onboardingProcessEpic } from './onboarding';
import {
  activateChannelEpic,
  deactivateChannelEpic,
  getUserSettingsEpic,
  validateChannelEpic,
  verifyChannelEpic,
} from './settings';
import { acknowledgeNotificationEpic, getNotificationListEpic } from './userNotifications';

export const rootEpic = combineEpics<Action, Action, RootState>(
  userLoginEpic,
  getUserInfoEpic,
  activateChannelEpic,
  deactivateChannelEpic,
  getUserSettingsEpic,
  validateChannelEpic,
  verifyChannelEpic,
  handleNonCriticalErrorsEpic,
  getProcessDefinitionsEpic,
  startProcessEpic,
  completeSignTaskEpic,
  completeTaskEpic,
  getFormByTaskIdEpic,
  getProcessInstancesEpic,
  getProcessInstancesHistoryEpic,
  getPendingTaskEpic,
  getFormByProcessDefinitionKeyEpic,
  startProcessWithFormEpic,
  onboardingProcessEpic,
  saveTaskEpic,
  getNotificationListEpic,
  acknowledgeNotificationEpic,
  getGroupedProcessDefinitionsEpic,
);
