import { createStyles } from '@material-ui/core';

const styles = () => createStyles({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
});

export default styles;
