import React from 'react';
import { WithTranslation } from 'react-i18next';
import { ROUTES } from 'constants/routes';
import { defaultCriticalErrorProps } from 'constants/errorProps';
import ErrorLayoutCommon from '#web-components/components/Layouts/Error';
import ErrorNavLink from './components';

type ErrorBoundaryProps = WithTranslation;

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  handleBackLinkClick = (path?: string) => {
    window.location.assign(path || ROUTES.PROCESS_LIST);
  };

  render() {
    const { hasError } = this.state;
    const { children, t } = this.props;

    if (hasError) {
      return (
        <ErrorLayoutCommon
          error={defaultCriticalErrorProps}
          reloadButtonCaption={t('components.errorLayout.reloadPage')}
          defaultErrorTitle={t('components.errorLayout.errorTitle')}
          defaultBackLinkTitle={t('components.errorLayout.backToHome')}
          defaultMessage={t('components.errorLayout.smthWentWrong')}
          defaultDescription={t('components.errorLayout.reloadPageAndContactAdministrator')}
          homePath={ROUTES.PROCESS_LIST}
          navLinkComponent={ErrorNavLink}
          appTitle={t('appTitle')}
          onBackLinkClick={this.handleBackLinkClick}
        />
      );
    }
    return children;
  }
}
