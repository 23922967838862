import { makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import StandardLayout from 'components/Layouts/Standard';
import {
  getProcessInstancesClean,
  getProcessInstancesRequest,
  selectProcessInstances,
  selectProcessInstancesIsLoaded,
  selectProcessInstancesIsLoading,
  selectProcessInstancesError,
  selectProcessInstancesFilterParams,
} from 'store/processInstances';
import {
  getProcessInstancesHistoryClean,
  getProcessInstancesHistoryRequest,
  selectProcessInstancesHistory,
  selectProcessInstancesHistoryError,
  selectProcessInstancesHistoryFilterParams,
  selectProcessInstancesHistoryIsLoaded,
  selectProcessInstancesHistoryIsLoading,
} from 'store/processInstancesHistory';
import { ProcessInstanceListTabs, TAB_QUERY_PARAM } from 'constants/tabs';
import { X_PATH } from 'constants/xPath';
import { selectPendingTaskError, selectPendingTaskIsLoading } from 'store/userTask';
import { FilterParams } from '#web-components/types/table';
import Tabs, { TabPanel } from '#web-components/components/Tabs';

import ActiveProcessInstanceTable from './components/ActiveProcessInstanceTable';
import styles from './ProcessInstanceListPage.styles';
import EndedProcessInstanceTable from './components/EndedProcessInstanceTable';

const useStyles = makeStyles(styles, { name: 'ProcessInstanceListPage' });

export default function ProcessInstanceListPage() {
  const { t } = useTranslation('processInstanceListPage');
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const processInstances = useSelector(selectProcessInstances);
  const processInstancesFilterParams = useSelector(selectProcessInstancesFilterParams);

  const processInstancesHistory = useSelector(selectProcessInstancesHistory);
  const processInstancesHistoryFilterParams = useSelector(selectProcessInstancesHistoryFilterParams);

  const processInstancesIsLoading = useSelector(selectProcessInstancesIsLoading);
  const processInstancesHistoryIsLoading = useSelector(selectProcessInstancesHistoryIsLoading);
  const pendingTaskIsLoading = useSelector(selectPendingTaskIsLoading);

  const processInstancesIsLoaded = useSelector(selectProcessInstancesIsLoaded);
  const processInstancesHistoryIsLoaded = useSelector(selectProcessInstancesHistoryIsLoaded);

  const processInstancesError = useSelector(selectProcessInstancesError);
  const processInstancesHistoryError = useSelector(selectProcessInstancesHistoryError);
  const pendingTaskError = useSelector(selectPendingTaskError);

  const isLoading = processInstancesIsLoading
    || processInstancesHistoryIsLoading
    || pendingTaskIsLoading;

  const getProcessInstances = useCallback((filterParams: FilterParams, isInitialRequest?: boolean) => {
    if (!processInstancesIsLoaded || !isInitialRequest) {
      dispatch(getProcessInstancesRequest({ filterParams, isInitialRequest }));
    }
  }, [dispatch, processInstancesIsLoaded]);

  const getProcessInstancesHistory = useCallback((filterParams: FilterParams, isInitialRequest?: boolean) => {
    if (!processInstancesHistoryIsLoaded || !isInitialRequest) {
      dispatch(getProcessInstancesHistoryRequest({ filterParams, isInitialRequest }));
    }
  }, [dispatch, processInstancesHistoryIsLoaded]);

  const handleTabChange = useCallback((search: string) => {
    history.push({ search });
  }, [history]);

  useEffect(() => {
    return function cleanup() {
      dispatch(getProcessInstancesHistoryClean());
      dispatch(getProcessInstancesClean());
    };
  }, [dispatch]);

  return (
    <StandardLayout
      title={t('pageTitle')}
      isLoading={isLoading}
      error={processInstancesError || processInstancesHistoryError || pendingTaskError}
      description={t('pageDescription')}
    >
      <Tabs
        classes={{ tabs: classes.tabsRoot }}
        tabProps={{ 'data-xpath': X_PATH.tabsButton }}
        tabQueryParam={TAB_QUERY_PARAM}
        onTabChange={handleTabChange}
        queryString={history.location.search}
      >
        <TabPanel title={t('components.tabs.inProgress')} code={ProcessInstanceListTabs.active}>
          <ActiveProcessInstanceTable
            processInstances={processInstances}
            getProcessInstances={getProcessInstances}
            filterParams={processInstancesFilterParams}
            isLoadingProcess={processInstancesIsLoading}
          />
        </TabPanel>
        <TabPanel title={t('components.tabs.closed')} code={ProcessInstanceListTabs.ended}>
          <EndedProcessInstanceTable
            processInstances={processInstancesHistory}
            getProcessInstances={getProcessInstancesHistory}
            filterParams={processInstancesHistoryFilterParams}
            isLoadingProcess={processInstancesHistoryIsLoading}
          />
        </TabPanel>
      </Tabs>
    </StandardLayout>
  );
}
