import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';

import StandardLayout from 'components/Layouts/Standard';
import useAuthentication from 'hooks/useAuthentication';
import { X_PATH } from 'constants/xPath';
import useQuery from 'hooks/useQuery';
import Typography from '#web-components/components/Typography';
import Button from '#web-components/components/Button';
import FlashMessage from '#web-components/components/FlashMessage';
import HourGlassIcon from '#web-components/components/Icons/HourGlassIcon/HourGlassIcon';

import styles from './LoginPage.styles';

const useStyles = makeStyles(styles, { name: 'Login' });

export default function LoginPage() {
  const authentication = useAuthentication();
  const location = useLocation<{ apiStatus?: number }>();
  const query = useQuery();
  const classes = useStyles();
  const { t } = useTranslation('loginPage');

  const handleLogin = useCallback((): void => {
    authentication.login();
  }, [authentication]);

  const apiStatus = location.state?.apiStatus;
  useEffect(() => {
    if (authentication.authenticated && apiStatus === 401) {
      authentication.clear();
    }
  }, [apiStatus, authentication]);

  if (authentication.authenticated) {
    if (query.has('logout')) {
      authentication.logout();
    } else {
      return <Redirect to="/" />;
    }
  }

  return (
    <StandardLayout disableBackground>
      <div className={classes.root}>
        <Typography variant="h2" className={classes.title}>
          {t('title')}
        </Typography>
        { authentication.beenAuthenticated
          ? (
            <FlashMessage
              status="warning"
              customIcon={HourGlassIcon}
              message={(
                <Typography variant="bodyText">
                  {t('sessionExpired')}
                </Typography>
              )}
            />
          ) : null }
        <Button onClick={handleLogin} data-xpath={X_PATH.loginButton}>
          {t('authorizeButtonLabel')}
        </Button>
      </div>
    </StandardLayout>
  );
}
