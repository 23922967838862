import { api } from 'api';
import { ChannelDto, ChannelType } from 'types/settings';

const SETTINGS_API_APPENDIX = 'api/settings/me';

export const getSettings = () => {
  return api.get$(`${SETTINGS_API_APPENDIX}`);
};

export const activateChannel = (channel: ChannelType, payload: { address?: string, verificationCode?: string }) => {
  return api.post$(`${SETTINGS_API_APPENDIX}/channels/${channel}/activate`, payload);
};

export const deactivateChannel = (channel: ChannelType) => {
  return api.post$(`${SETTINGS_API_APPENDIX}/channels/${channel}/deactivate`, {
    deactivationReason: 'USER_DEACTIVATED',
  });
};

export const validateChannel = (channel: ChannelType, payload: ChannelDto) => {
  return api.post$(`${SETTINGS_API_APPENDIX}/channels/${channel}/validate`, payload);
};

export const verifyChannel = (channel: ChannelType, payload: ChannelDto) => {
  return api.post$(`${SETTINGS_API_APPENDIX}/channels/${channel}/verify`, payload);
};
