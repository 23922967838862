import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';
import { push } from 'connected-react-router';

import SidebarLayout from 'components/Layouts/Sidebar';
import { selectCurrentUserInfo } from 'store/currentUser';
import {
  deactivateChannelRequest,
  getSettingsClean,
  getSettingsRequest,
  selectChannelDiia,
  selectChannelEmail,
  selectSettingsError,
  selectSettingsIsLoading,
} from 'store/settings';
import { ROUTES } from 'constants/routes';
import { ChannelType } from 'types/settings';
import { EmailIcon, LogoDiiaIcon, PointUpIcon } from '#web-components/components/Icons';
import FlashMessage, { ViewType } from '#web-components/components/FlashMessage';
import Typography from '#web-components/components/Typography';
import PropertyValueItem from '#web-components/components/PropertyValueItem';
import ColoredBox from '#web-components/components/ColoredBox';
import InlineButton from '#web-components/components/InlineButton';

import styles from './ProfilePage.styles';
import ChannelItem from './components/ChannelItem';
import ModalDiactivateChannel from './components/ModalDiactivateChannel';

const useStyles = makeStyles(styles, { name: 'ProfilePage' });

export default function ProfilePage() {
  const { t } = useTranslation('profilePage');
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUserInfo);
  const settingsEmail = useSelector(selectChannelEmail);
  const settingsDiia = useSelector(selectChannelDiia);
  const isLoading = useSelector(selectSettingsIsLoading);
  const getSettingsError = useSelector(selectSettingsError);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [profileChannel, setProfileChannel] = useState<{
    channel: ChannelType,
    value?: string,
  }>({ channel: ChannelType.EMAIL, value: '' });

  const handleBackLinkClick = useCallback((e) => {
    e.preventDefault();
    dispatch(push(ROUTES.PROCESS_LIST));
  }, [dispatch]);
  const onEditEmail = useCallback(() => {
    dispatch(push(ROUTES.PROFILE_EDIT, { isEditEmail: true }));
  }, [dispatch]);

  const onCreateEmail = useCallback(() => {
    dispatch(push(ROUTES.PROFILE_EDIT));
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    setOpen(false);
    const { channel } = profileChannel;
    dispatch(deactivateChannelRequest({ channel }));
  }, [dispatch, profileChannel]);

  const handleDeactivate = useCallback((channel: ChannelType, value?: string) => () => {
    setOpen(true);
    setProfileChannel({ channel, value });
  }, []);

  const handleActivate = useCallback((channel: ChannelType, address?: string) => () => {
    const route = ROUTES.PROFILE_CONFIRM_CHANNEL.replace(':channel', channel);
    const query = channel === ChannelType.EMAIL ? `?address=${address}` : '';

    dispatch(push(`${route}${query}`));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSettingsRequest());
    return () => {
      dispatch(getSettingsClean());
    };
  }, [dispatch]);

  return (
    <SidebarLayout
      backLinkPath=""
      title={t('title')}
      onBackLinkClick={handleBackLinkClick}
      backLinkCaption={t('backLinkCaption')}
      isLoading={isLoading}
    >
      <ColoredBox className={classes.profileBox}>
        <Typography className={classes.name} variant="h5">{currentUser?.fullName || currentUser?.name}</Typography>
        {currentUser?.drfo && (
          <PropertyValueItem
            className={classes.infoItem}
            property={t('common:userInfo.rnokpp')}
            value={currentUser?.drfo}
          />
        )}
        {currentUser?.edrpou && (
          <PropertyValueItem
            className={classes.infoItem}
            property={t('common:userInfo.edrpou')}
            value={currentUser?.edrpou}
          />
        )}
      </ColoredBox>
      <div className={classes.settingsTitle}>
        <Typography variant="h3">
          {t('settings.title')}
        </Typography>
      </div>
      <FlashMessage
        status="warning"
        customIcon={PointUpIcon}
        message={t('settings.notification')}
        viewType={ViewType.plain}
      />
      {
        getSettingsError && (
          <FlashMessage
            status="error"
            title={t('common:errors.notification.default.title')}
            message={t('settings.errorNotification')}
            viewType={ViewType.plain}
          />
        )
      }

      <div className={classes.borderBottom}>
        <ChannelItem
          label={t('settings.pushMessage')}
          channel={getSettingsError ? undefined : t('settings.applicationDiia')}
          placeholder={(
            <Typography
              variant="h7"
              component="span"
              className={classes.emailMessage}
            >
              {t('settings.errorReading')}
            </Typography>
          )}
          icon={LogoDiiaIcon}
          hint={(
            <Box
              component="span"
            >
              {t('settings.hintDiia')}
              <Link
                className={classes.link}
                to={{ pathname: 'https://diia.gov.ua/faq/8' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('settings.linkDiia')}
              </Link>
            </Box>
          )}
          isActive={!!settingsDiia?.activated}
          onActivate={handleActivate(ChannelType.DIIA)}
          onDeactivate={handleDeactivate(ChannelType.DIIA, t('settings.applicationDiia'))}
        />
      </div>

      <ChannelItem
        label={t('settings.email')}
        channel={settingsEmail?.address}
        placeholder={getSettingsError
          ? (
            <Typography
              variant="h7"
              component="span"
              className={classes.emailMessage}
            >
              {t('settings.errorReading')}
            </Typography>
          )
          : (
            <InlineButton size="medium" leftIcon={<EmailIcon />} onLinkClick={onCreateEmail}>
              {t('settings.enterEmailAddress')}
            </InlineButton>
          )}
        icon={EmailIcon}
        hint={t('settings.hintEmail')}
        isActive={!!settingsEmail?.activated}
        onEdit={onEditEmail}
        onActivate={handleActivate(ChannelType.EMAIL, settingsEmail?.address)}
        onDeactivate={handleDeactivate(ChannelType.EMAIL, settingsEmail?.address)}
      />

      <ModalDiactivateChannel
        channel={profileChannel.value}
        isOpen={open}
        onOpenChange={setOpen}
        onSubmit={handleSubmit}
      />

    </SidebarLayout>
  );
}
