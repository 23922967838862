import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import useAuthentication from 'hooks/useAuthentication';
import Link from '#web-components/components/Link';
import styles from './HeaderLinks.styles';

const useStyles = makeStyles(styles, { name: 'HeaderLinks' });

const HEADER_LINKS = [
  {
    title: 'appSections.myNotifications.title',
    path: ROUTES.NOTIFICATION_LIST,
  },
  {
    title: 'appSections.processDefinition.title',
    path: ROUTES.PROCESS_LIST,
  },
  {
    title: 'appSections.myServices.title',
    path: ROUTES.PROCESS_INSTANCE_LIST,
  },
];

export default function HeaderLinks() {
  const authentication = useAuthentication();
  const { t } = useTranslation('common');
  const classes = useStyles();

  if (!(authentication.authenticated)) {
    return null;
  }
  return (
    <div className={classes.root}>
      {HEADER_LINKS.map((link) => (
        <Link
          key={link.path}
          to={link.path}
          component={RouterNavLink}
          className={classes.link}
          activeClassName={classes.active}
        >
          {t(link.title)}
        </Link>
      ))}
    </div>
  );
}
