import { api } from 'api';

import { HistoryFilterOptions } from '#shared/types/common';
import { NOTIFICATIONS_API_APPENDIX } from 'constants/baseUrl';
import { buildURLQuery } from '#shared/utils/apiHelpers';

export const getNotificationList = (requestParams: Omit<HistoryFilterOptions, 'sort'>) => {
  const urlQuery = buildURLQuery(requestParams);
  return api.get$(
    `${NOTIFICATIONS_API_APPENDIX}/inbox?${urlQuery}`,
  );
};

export const acknowledgeNotification = (notificationId:string) => {
  return api.post$(`${NOTIFICATIONS_API_APPENDIX}/inbox/${notificationId}/ack`, {});
};
