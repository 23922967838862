import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { API_URL } from 'constants/baseUrl';
import { UNREGISTERED_ROLES } from 'constants/roles';
import {
  clearInfo,
  getInfoRequest,
  selectCurrentUserAuthenticated,
  selectCurrentUserBeenAuthenticated,
  selectCurrentUserError,
  selectCurrentUserInfo,
  selectCurrentUserInitialized,
} from 'store/currentUser';
import { getInfo } from 'api/user';
import { ActivityHelper } from '#shared/utils/activity';

const activity = new ActivityHelper(() => {
  getInfo().subscribe();
}, ['mousemove', 'keypress'], 150 * 1000);

export default function useAuthentication() {
  const dispatch = useDispatch();
  const location = useLocation();
  const initialized = useSelector(selectCurrentUserInitialized);
  const authenticated = useSelector(selectCurrentUserAuthenticated);
  const beenAuthenticated = useSelector(selectCurrentUserBeenAuthenticated);
  const info = useSelector(selectCurrentUserInfo);
  const error = useSelector(selectCurrentUserError);

  const clear = useCallback(() => {
    dispatch(clearInfo());
  }, [dispatch]);

  const logout = useCallback(() => {
    window.location.assign(`${API_URL}/logout`);
  }, []);

  const login = useCallback(() => {
    window.location.assign(`${API_URL}/process-list`);
  }, []);

  const hasRealmRole = useCallback(
    (role: string): boolean => (info?.roles ? info.roles.includes(role) : false),
    [info?.roles],
  );

  const hasUnregisteredRealmRole = useMemo(
    (): boolean => (
      info?.roles
        ? Object.values(UNREGISTERED_ROLES).some(
          (role) => info.roles.includes(role),
        ) : false
    ),
    [info?.roles],
  );

  const hasResourceRole = (role: string) => Boolean(role);

  useEffect(() => {
    if ((!initialized) || (authenticated && !info)) {
      dispatch(getInfoRequest());
    }
  }, [authenticated, dispatch, info, initialized]);

  useEffect(() => {
    return () => {
      if (error) {
        dispatch(getInfoRequest());
      }
    };
  }, [location, error, dispatch]);

  useEffect(() => {
    if (authenticated) {
      activity.enable();
    } else {
      activity.disable();
    }
  }, [authenticated]);

  return {
    login,
    logout,
    clear,
    hasRealmRole,
    hasResourceRole,
    hasUnregisteredRealmRole,
    initialized,
    authenticated,
    beenAuthenticated,
    info,
    error,
  };
}
