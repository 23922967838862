import { ENTITIES } from 'constants/entities';
import { HistoryFilterOptions } from '#shared/types/common';
import { buildURLQuery } from '#shared/utils/apiHelpers';

import { api } from '.';

export const getProcessInstancesList = (requestParams: HistoryFilterOptions) => {
  const urlQuery = buildURLQuery(requestParams);
  return api.get$(
    `api/runtime/${ENTITIES.PROCESS_INSTANCE_PLURAL}?${urlQuery}`,
  );
};
