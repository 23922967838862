import React from 'react';
import { useTranslation } from 'react-i18next';
import isNumber from 'lodash/isNumber';
import { X_PATH } from 'constants/xPath';
import { IntlLanguage } from 'localization';
import { Order, ColumnDefinition, FilterParams } from '#web-components/types/table';

import MdtuTable from '#web-components/components/Table';

interface Props<T> {
  columnDefinitions: ColumnDefinition[];
  list: T[];
  order: Order;
  orderField: string;
  onOrderChange?: (orderField: string, order: Order) => void;
  onFilterChange?: (filterParams: FilterParams) => void;
  emptyPlaceholder?: string;
  emptyPlaceholderIcon?: string | JSX.Element;
  hideEmptyPlaceholder?: boolean;
  pagination?: {
    page: number;
    rowsPerPage: number;
    totalItems?: number;
  }
}

export default function Table<T>(props: Props<T>) {
  const { t } = useTranslation('common');
  const {
    columnDefinitions,
    list,
    order,
    orderField,
    onOrderChange,
    emptyPlaceholder,
    emptyPlaceholderIcon,
    hideEmptyPlaceholder,
    pagination,
    onFilterChange,
  } = props;
  return (
    <MdtuTable
      columnDefinitions={columnDefinitions}
      list={list}
      order={order}
      orderField={orderField}
      onOrderChange={onOrderChange}
      emptyPlaceholder={emptyPlaceholder || t('components.table.emptyPlaceholder')}
      emptyPlaceholderIcon={emptyPlaceholderIcon}
      hideEmptyPlaceholder={hideEmptyPlaceholder}
      header={{
        'data-xpath': X_PATH.tableHeadCell,
      }}
      onFilterChange={onFilterChange}
      pagination={{
        ...pagination,
        labelRowsPerPage: t('components.table.perPage'),
        getDisplayedRowsLabel: (params) => (isNumber(params.count)
          ? t('components.table.pagination', params)
          : t('components.table.paginationNoCount', params)),
        'data-xpath': {
          tablePageCountSelect: X_PATH.tablePageCountSelect,
          tableFirstPage: X_PATH.tableFirstPage,
          tablePrevPage: X_PATH.tablePrevPage,
          tableNextPage: X_PATH.tableNextPage,
          tableLastPage: X_PATH.tableLastPage,
        },
        ariaLabel: {
          firstPage: t('components.table.aria.firstPage'),
          prevPage: t('components.table.aria.prevPage'),
          nextPage: t('components.table.aria.nextPage'),
          lastPage: t('components.table.aria.lastPage'),
        },
      }}
      data-xpath={{
        tableContainer: X_PATH.tableContainer,
        tableRow: X_PATH.tableRow,
      }}
      locale={IntlLanguage}
    />
  );
}
