import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getPendingTaskRequest } from 'store/userTask';
import Button, { ButtonVariants } from '#web-components/components/Button';
import styles from './ActiveProcessInstanceTableAction.styles';

interface ActiveProcessInstanceTableActionProps {
  processInstanceId: string,
}
const useStyles = makeStyles(styles, { name: 'ActiveProcessInstanceTableAction' });

export default function ActiveProcessInstanceTableAction(
  { processInstanceId }: ActiveProcessInstanceTableActionProps,
) {
  const classes = useStyles();
  const { t } = useTranslation('processInstanceListPage');
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    return dispatch(getPendingTaskRequest({ processInstanceId }));
  }, [dispatch, processInstanceId]);

  return (
    <Button variant={ButtonVariants.secondary} size="small" className={classes.button} onClick={handleClick}>
      {t('components.processInstanceTable.button.continue')}
    </Button>
  );
}
